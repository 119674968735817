import React from 'react'
import { FaLinkedin, FaGithub, FaTwitter, FaMediumM, FaInstagram } from 'react-icons/fa'

const HeaderSocials = () => {
  return (
      <div className='header_socials'>
         <a href="https://pk.linkedin.com/in/mubashir-odho-261533196" target="_blank"><FaLinkedin /></a>
        <a href="https://www.instagram.com/Mubashirodho/" target="_blank"><FaInstagram/></a>
        <a href="https://twitter.com/MubashirOdho" target="_blank"><FaTwitter /></a>
        <a href="https://github.com/ODHO" target="_blank"><FaGithub /></a>        
    </div>
  )
}

export default HeaderSocials