import React from "react";
import "./project.css";
import IMG1 from "../../assets/partyfixers.png";
import IMG2 from "../../assets/Sawari.png";
import IMG3 from "../../assets/metowork.png";
import IMG4 from "../../assets/firstcallcoffee.png";
import IMG5 from "../../assets/pokernow.png";
import IMG6 from "../../assets/jewellry.png";

const projects = [
  {
    id: 1,
    image: IMG4,
    title: "First Call Coffee - React.js-PHP Laravel-Bootstrap",
    // github: "https://github.com/Somdotta07/recipe-blog-1",
    demo: "https://firstcallcoffee.vercel.app/",
  },
  {
    id: 2,
    image: IMG5,
    title: "Poker Now Game- React.js And Bootstrap",
    // github: "https://github.com/Somdotta07/space-travelers-hub",
    demo: "https://poker-now.vercel.app/",
  },
  {
    id: 3,
    image: IMG6,
    title: "Jewellry - React.js And Tailwind-css E-commerce",
    // github: "https://github.com/Somdotta07/Awesome_Books",
    demo: "https://jewellry-65a00.firebaseapp.com/",
  },
  {
    id: 4,
    image: IMG1,
    title: "PartyFixers - React-Redux And Tailwind-css",
    // github: "https://github.com/Somdotta07/Somarven-Arenas-frontend",
    demo: "https://partyfixers-53c7d.firebaseapp.com/",
  },
  {
    id: 5,
    image: IMG2,
    title: "Sawari - Rent A Car - React.js And Tailwind-css",
    // github: "https://github.com/Somdotta07/js-capstone",
    demo: "https://sawari-website-6e835.web.app/",
  },
  {
    id: 6,
    image: IMG3,
    title: "Metowork - Freelancer - React.js And Tailwind-css",
    // github: "https://github.com/Somdotta07/react-redux-capstone",
    demo: "https://me2work-f2b47.firebaseapp.com/",
  },
];
const Projects = () => {
  return (
    <section id="project">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container project_container">
        {projects.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="project_item">
              <div className="project_item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="project-item-cta">
                {/* <a
            href={github}
            className="btn"
          >
            Github
          </a> */}
                <a
                  href={demo}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
