/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './footer.css'
import {FaAngellist, FaTwitter, FaGithub, FaLinkedin, FaMedium, FaHeart, FaInstagram} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      {/* <a href="#" className='footer_logo'>DevSom</a> */}
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#project'>Projects</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>        
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className="footer_socials">
        <a href="https://pk.linkedin.com/in/mubashir-odho-261533196" target="_blank"><FaLinkedin /></a>
        <a href="https://www.instagram.com/Mubashirodho/" target="_blank"><FaInstagram /></a>
        <a href="https://twitter.com/MubashirOdho" target="_blank"><FaTwitter /></a>
        <a href="https://github.com/ODHO" target="_blank"><FaGithub /></a>        
        {/* <a href="https://medium.com/@jhilick.s"><FaMedium /></a> */}
      </div>
      <div className="footer_copyright align-items-center">
        <small>&copy;2020-2024 Created With<FaHeart style={{color:'black'}}/> By ODHO</small>
      </div>
    </footer>
  )
}

export default Footer
