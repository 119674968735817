import React from "react";
import "./about.css";
import Me from "../../assets/avatar.jpg";
import { FaAward, FaCertificate, FaFolder } from "react-icons/fa";
import Header from "../header/Header";

const About = () => {
  return (
    <section id="about">
      {/* <Header /> */}
      <h5>Hello I'm</h5>
      <h2>MUBASHIR ODHO</h2>
      {/* <h5 className="text-light"> Full-stack Developer</h5> */}
      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={Me} alt="About me" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <small>5+ Years Working</small>
            </article>
            <article className="about_card">
              <FaCertificate className="about_icon" />
              <h5>Certificates</h5>
              <small>7+</small>
            </article>
            <article className="about_card">
              <FaFolder className="about_icon" />
              <h5>Projects</h5>
              <small>200+ projects completed</small>
            </article>
          </div>
          <p>
            I’m a certified full-stack developer, who enjoys building
            interactive interfaces with JavaScript, React and Ruby On Rails.
            <br />
            <br />
            I Am Available For FreeLance
            <br />
            <br />
            I'm open to Full-stack(remote/onsite) opportunities with a passion
            of Front-end development. If you like what you see and have a
            project you need coded, don’t hestiate to contact me.
          </p>
          <a href="tel:+923222214016" className="btn btn-primary">
            {" "}
            Hire me
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
