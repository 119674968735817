import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./contact.css";
import { MdEmail } from "react-icons/md";
import { FaLinkedin, FaGithub } from "react-icons/fa";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start the loader

    const serviceId = "service_0b1amxs";
    const templateId = "template_l53gpns";
    const publicKey = "49Z3d-YtrL6keQniC";

    emailjs
      .send(
        serviceId,
        templateId,
        {
          from_name: name,
          from_email: email,
          phone: phone,
          message: message,
        },
        publicKey
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
        setIsSubmitted(true);
        setLoading(false); // Stop the loader
      })
      .catch((error) => {
        console.error("Email failed to send:", error);
        setIsSubmitted(false);
        setLoading(false); // Stop the loader
      });
  };

  return (
    <section id="contact">
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact_container">
        <div className="contact_options">
          <article className="contact_option">
            <MdEmail className="contact_option-icon" />
            <h4>Email</h4>
            <h5>mubashirodho@gmail.com</h5>
            <a
              href="mailto:mubashiodho@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article>
          <article className="contact_option">
            <FaLinkedin className="contact_option-icon" />
            <h4>LinkedIn</h4>
            <h5>mubashir-odho</h5>
            <a
              href="https://pk.linkedin.com/in/mubashir-odho-261533196"
              target="_blank"
              rel="noreferrer"
            >
              Send a message
            </a>
          </article>
          <article className="contact_option">
            <FaGithub className="contact_option-icon" />
            <h4>GitHub</h4>
            <h5>ODHO</h5>
            <a href="https://github.com/ODHO" target="_blank" rel="noreferrer">
              Visit Profile
            </a>
          </article>
        </div>
        {isSubmitted ? (
          <div>
            <h2>Thank you for submitting the form!</h2>
            <p>We will get back to you as soon as possible.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </label>
            <br />
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
            <br />
            <label>
              Phone:
              <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </label>
            <br />
            <label>
              Message:
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </label>
            <br />
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Message"}
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Contact;
